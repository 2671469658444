import { getDetailApi, updateDetailApi } from "../Components/constant/Api";
import { myApi } from "./api";

export const detailApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getDetail: builder.query({
      query: (post) => ({
        url: getDetailApi+ "/"+post.type,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" :"";
      },
      providesTags: (_) => ["Detail"],
    }),
    setDetail: builder.mutation({
        query: (post) => ({
          url: updateDetailApi,
          method: "POST",
          body:post
       
        }),
       
        invalidatesTags: (_) => ["Detail"],
      }),
  }),
});

export const {useGetDetailQuery,useSetDetailMutation } = detailApi;