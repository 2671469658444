import { baseUrl } from "./BaseUrl";


export const loginApi=baseUrl+"api/admin/login"
export const changePasswordApi=baseUrl+"api/admin/change-password"

//================== Detail ===================
export const getDetailApi="api/crick/get-detail"
export const updateDetailApi="api/admin/update-detail"

//=================== News =======================
export const getNewsListApi="api/crick/news-list"
export const getNewsListByIdApi=baseUrl+"api/crick/news-list-by-id"
export const updateNewsStatusApi="api/admin/update-news-status"

//==================== Series ======================
export const getSeriesApi="api/crick/get-series"
export const updateSeriesStatusApi="api/admin/update-series"
export const getMatchBySeriesIdApi=baseUrl+"api/crick/get-match-by-series-id"

//======================== Matches =====================
export const getMatchListApi="api/crick/match-list"
export const getMatchByIdApi=baseUrl+"api/crick/get-match-by-id"

//======================== Players =======================
export const getPlayerListApi="api/admin/get-players"
export const getPlayerListByMatchIdApi="/api/admin/get-players-by-match-id"

//======================= Squad ========================
export const getSquadByMatchIdApi=baseUrl+"api/crick/get-squad-by-match-id"

//======================= Counts ======================
export const getTotalSeriesApi="api/admin/total-series"
export const getTotalFixtureApi="api/admin/total-fixture"
export const getTotalPlayersApi="api/admin/total-players"
export const getTotalNewsApi="api/admin/total-news"
export const getTotalTeamsApi="api/admin/total-teams"

//======================= Commentry ==========================
export const getCommentryApi=baseUrl+"api/crick/get-commentry-by-match_id"

//======================= Score ===============================
export const getmatchScoreApi=baseUrl+"api/crick/get-score-by-match-id"

//======================== Team================================
export const getTeamApi="api/crick/get-team"
export const getMatchByTeamIdApi=baseUrl+"api/crick/get-match-by-team-id"
