import React, { useState, useEffect } from "react";

function FullCommentry(props) {
  const [record, setRecord] = useState({});
  const [keys, setKeys] = useState([]);
  const [commentry, setCommentry] = useState({});
  const [inning, setInning] = useState("");

  useEffect(() => {
    if (props.commentry.commentry) {
      let comm=JSON.parse(props.commentry.commentry)
      setRecord(JSON.parse(props.commentry.commentry));
      let arr = [];
      let json = JSON.parse(props.commentry.commentry);
      if (json) {
        for (let key in json) {
          arr.push(key);
        }
        if (arr.length > 0) {
          setKeys(arr);
          
          setCommentry(json[arr[0]]);
          if(!inning){
            setInning(arr[0]);
          }
          if(inning){

            setCommentry(comm[inning]);
          }
        }
      }
    }
  }, [props,inning]);

  const filterCommentry = (type) => {
    setCommentry(record[type]);
  };
  const [isVisible, setIsVisible] = useState(false);

  const scrollFunction = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const topFunction = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollFunction);
    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, []);

  return (
    <div className="card p-3 p-md-4">
      <div>
        <ul className="nav nav-pills nav-fill2 mb-4">
          {keys.map((list, index) => {
            return (
              <li
                className="nav-item"
                key={index}
                onClick={() => {
                  setInning(list);
                  filterCommentry(list);
                }}
              >
                <span
                  className={`nav-link ${inning === list ? "active" : ""}`}
                  aria-current="page"
                >
                  {list}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      {record?.inning}
      <div>
        {Object.entries(commentry).map(([over, commentaryArray]) => (
          <div key={over}>
            {/* <h2>{over}</h2> */}
            {commentaryArray.map((commentary, index) => (
                <div key={index}>
                 
                 
                  {commentary.data.over? (
                    <div className="d-flex box-class">
                      <div className="boxes1 box-color">
                        <span>
                          <strong>{commentary.data.over}</strong>
                        </span>
                      </div>
                      <div className="boxes box-color">
                        <span>Runs Scored :{commentary.data.runs}</span>
                      </div>
                      <div className="boxes box-color">
                        <p>Score after {commentary.data.over} overs</p>
                        <p>
                          {commentary.data.team} {commentary.data.team_score}-
                          {commentary.data.team_wicket}
                        </p>
                      </div>
                      <div className="boxes box-color">
                        <p>
                          <span>{commentary.data.batsman_1_name}</span>
                          <span className="ms-2">
                            {commentary.data.batsman_1_runs}(
                            {commentary.data.batsman_1_balls})
                          </span>
                        </p>
                        <p>
                          <span>{commentary.data.batsman_2_name}</span>
                          <span className="ms-2">
                            {commentary.data.batsman_2_runs}(
                            {commentary.data.batsman_2_balls})
                          </span>
                        </p>
                      </div>
                      <div className="boxes box-color">
                        <p>
                          <p>{commentary.data.bolwer_name}</p>
                          <span>
                            {commentary.data.bolwer_overs}-
                            {commentary.data.bolwer_maidens}-
                            {commentary.data.bolwer_runs}-
                            {commentary.data.bolwer_wickets}
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div key={index} className="commentry-span">
                    <span>
                      <strong>{commentary.data.overs}</strong>
                    </span>
                    <div>
                      <p>{commentary.data.description?commentary.data.description:commentary.data.title}</p>
                    </div>
                  </div>
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>
      {isVisible && (<button
      onClick={topFunction}
      class="btn btn_man btn-icon"
      id="back-to-top"
    >
      <i class="ri-arrow-up-line"></i>
    </button>)}
    </div>
  );
}

export default FullCommentry;
