import React, { useEffect } from 'react';
import Login from "./Components/auth/Login";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {useAuth } from "./AuthContext";
import Dashboard from "./Components/Widgets/Dashboard"
import TermsAndConditions from "./Components/pages/Detail/TermsAndConditions";
import PrivacyPolicy from "./Components/pages/Detail/PrivacyPolicy";
import ProtectedRoute from "./ProtectedRoute";
import PendingNews from './Components/pages/News/PendingNews';
import DisApproveNews from './Components/pages/News/DisApproveNews';
import ApproveNews from './Components/pages/News/ApproveNews';
import NewsDetail from './Components/pages/News/NewsDetail';
import Series from './Components/pages/Series/Series';
import MatchListByseries from './Components/pages/Series/MatchListBySeries';
import Matches from './Components/pages/Matches/Matches';
import Players from './Components/pages/Players/Players';
import PlayersListByMatchId from './Components/pages/Matches/PlayersListByMatchId';
import MatchDetail from './Components/pages/Matches/MatchDetail';
import Team from './Components/pages/Teams/Team';
import MatchListByTeam from './Components/pages/Teams/MatchListByTeam';
import LiveMatchList from './Components/pages/Matches/LiveMatchList';

function App() {
  const { login } = useAuth();

  useEffect(() => {
    // Check if a JWT token is stored in localStorage and log in the user if found
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);
  return (
    <div className="App">
    
        <Router>
          <Routes>
           
            <Route path="/" element={<Login />} />
            <Route
              path="/dashboard"
              element={<ProtectedRoute component={<Dashboard />} />}
            />
            <Route
              path="/TAC"
              element={<ProtectedRoute component={<TermsAndConditions />} />}
            />
            <Route
              path="/privacyPolicy"
              element={<ProtectedRoute component={<PrivacyPolicy />} />}
            />
            <Route
              path="/news-pending"
              element={<ProtectedRoute component={<PendingNews />} />}
            />
            <Route
              path="/news-approved"
              element={<ProtectedRoute component={<ApproveNews />} />}
            />
            <Route
              path="/news-disapproved"
              element={<ProtectedRoute component={<DisApproveNews />} />}
            />
            <Route
              path="/news-detail/:id"
              element={<ProtectedRoute component={<NewsDetail />} />}
            />
             <Route
              path="/series"
              element={<ProtectedRoute component={<Series />} />}
            />
            <Route
              path="/match-list-by-series/:id"
              element={<ProtectedRoute component={<MatchListByseries />} />}
            />
            <Route
              path="/match/:type/:status"
              element={<ProtectedRoute component={<Matches />} />}
            />
            <Route
              path="/live-matches"
              element={<ProtectedRoute component={<LiveMatchList />} />}
            />
            <Route
              path="/players"
              element={<ProtectedRoute component={<Players />} />}
            />
            <Route
              path="/player-list-by-match/:id"
              element={<ProtectedRoute component={<PlayersListByMatchId />} />}
            />
            <Route
              path="/match-detail/:id"
              element={<ProtectedRoute component={<MatchDetail />} />}
            />
             <Route
              path="/teams"
              element={<ProtectedRoute component={<Team />} />}
            />
            <Route
              path="/match-list-by-team/:id/:type"
              element={<ProtectedRoute component={<MatchListByTeam />} />}
            />
          </Routes>
        </Router>
   
      <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
    </div>
   
  );
}

export default App;
