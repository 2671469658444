import React,{useState} from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../Widgets/Header";
import Navbar from "../../Widgets/Navbar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {useGetSeriesQuery, useUpdateSeriesStatusMutation} from "../../../redux/seriesApi"

function Series() {
  const [disable, setDisable] = useState(false);
  const {isLoading,data,refetch}=useGetSeriesQuery("")
  const [updateSeriesStatus] = useUpdateSeriesStatusMutation();
 
  const updateStatus = async (id, status) => {
    setDisable(true);
    const data = {
      id: id,
      status: status,
    };
    updateSeriesStatus(data).then((res) => {
      if (res.data.status) {
        toast.dismiss();
        toast.success(res.data.message);
        refetch();
        refetch();
        setDisable(false);
      } else {
        toast.error(res.data.message);
      }
    });
  };

  const columns = [
    {
      key: "Sr.No.",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "series",
      text: "Series",
      className: "title",
      align: "left",
      sortable: true,
    },

    {
      key: "image",
      text: "Image",
      className: "img",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {/* <img style={{ height: "50px" }} src={record.image} alt="" /> */}
            <img src="/assets/images/logo-icon.png" alt="playerimg" style={{ height: "50px" }}/>
          </>
        );
      },
    },
    {
      key: "series_status",
      text: "Status",
      className: "status",
      align: "left",
      sortable: true,
    },
    {
      key: "series_date",
      text: "Series Date",
      className: "date",
      align: "left",
      sortable: true,
    },
    {
      key: "total_matches",
      text: "Total Matches",
      className: "total",
      align: "left",
      sortable: true,
    },
    {
      key: "start_date",
      text: "Start Date",
      className: "date",
      align: "left",
      sortable: true,
    },
    {
      key: "end_date",
      text: "End Date",
      className: "data",
      align: "left",
      sortable: true,
    },

    {
      key: "views",
      text: "Match List",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link to={`/match-list-by-series/${record.series_id}`}>List</Link>
          </>
        );
      },
    },
    {
      key: "Action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button onClick={()=>{updateStatus(record.id)}}  disabled={disable}>{record.is_trending==='1'?"Trending":"Not Trending"}</button>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
 

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
              <div className="section-heading">
                <h2>
                  <b>Series List</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-5">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center"></div>
                  </div>
                  {isLoading?<div class="text-center">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>: <ReactDatatable
                    config={config}
                    records={data.data}
                    columns={columns}
                  />}
                 
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Series;
