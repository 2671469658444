import * as opsService from "./Ops";
import config from "../constant/Config";
import {
  getNewsListByIdApi,
} from "../constant/Api";


export const getNewsListById = async (data) => {
  let result = await opsService.postdata(getNewsListByIdApi, data, config);
  return result;
};
