import React, { useEffect, useState } from "react";

function Squad(props) {
  // const [squadDetail, setSquadDetail] = useState({})
  const [teamABench, setTeamABench] = useState([]);
  const [teamBBench, setTeamBBench] = useState([]);
  const [teamASquad, setTeamASquad] = useState([]);
  const [teamBSquad, setTeamBSquad] = useState([]);
  const [teamAShortName, setTeamAShortName] = useState("")
  const [teamBShortName, setTeamBShortName] = useState("")
  const [teamAImage, setTeamAImage] = useState("")
  const [teamBImage, setTeamBImage] = useState("")
  useEffect(() => {
    if (props.squadDetail) {
      let data = props.squadDetail;
    
      // setSquadDetail(data)
      setTeamABench(data?.team_a_data?.bench);
      setTeamBBench(data?.team_b_data?.bench);
      setTeamASquad(data?.team_a_data?.squad);
      setTeamBSquad(data?.team_b_data?.squad);
      setTeamAShortName(data?.team_a_data?.team_a_short)
      setTeamBShortName(data?.team_b_data?.team_b_short)
      setTeamAImage(data?.team_a_data?.team_a_image)
      setTeamBImage(data?.team_b_data?.team_b_image)
     
    }
   
  }, [props]);
 
  return (
    <div className="card p-4 p-md-5">
      <div>
        <div className="header-team">
            <div>
               <img src={teamAImage} alt="teamAimage"/>
               <span>{teamAShortName}</span>
            </div>
            <div>
            <img src={teamBImage} alt="teamAimage"/>
            <span>{teamBShortName}</span>  
            </div>
        </div>
      </div>
      {teamASquad?.length > 0 && teamBSquad?.length > 0 ? (
        <div>
          <div>
            <h4 className="text-center  p-4">Playing XI</h4>
          </div>
          <div>
          <div className="row">
            <div className="col-md-6 pe-0">
              {teamASquad?.map((list) => {
                
                return (
                    
                  <div key={list.player_id} className="player-box cb-player-name-left">
                    <div className="img_box_player">
                      {/* <img src={list.image} alt="playerimg" /> */}
                      <img src="/assets/images/logo-icon.png" alt="playerimg" />
                    </div>
                    <div className=" cb-player-name">
                      <p >{list.player_name}</p>
                      <span>{list.player_role}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-md-6 ps-0">
              {teamBSquad?.map((list) => {
                return (
                  <div key={list.player_id} className="player-box  cb-player-name-right">
                    
                    <div className="cb-player-name ml-auto">
                      <p>{list.player_name}</p>
                      <span>{list.player_role}</span>
                    </div>
                    <div className="img_box_player">
                      {/* <img src={list.image} alt="playerimg" /> */}
                      <img src="/assets/images/logo-icon.png" alt="playerimg" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        </div>
      ) : (
        ""
      )}

      <div >
        <div>
          <h4 className="text-center  p-4">
            {teamASquad?.length > 0 && teamBSquad?.length > 0
              ? "Bench"
              : "Squads"}
          </h4>
        </div>
        <div>
          <div className="row">
            <div className="col-6 pe-0">
              {teamABench?.map((list) => {
                
                return (
                    
                  <div key={list.player_id} className="player-box  cb-player-name-left">
                     <div className="img_box_player">
                      {/* <img src={list.image} alt="playerimg" /> */}
                      <img src="/assets/images/logo-icon.png" alt="playerimg" />
                    </div>
                    <div className="cb-player-name">
                      <p>{list.player_name}</p>
                      <span>{list.player_role}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-6 ps-0">
              {teamBBench?.map((list) => {
                return (
                  <div key={list.player_id} className="player-box  cb-player-name-right">
                    
                    <div className="cb-player-name ml-auto">
                      <p>{list.player_name}</p>
                      <span>{list.player_role}</span>
                    </div>
                    <div className="img_box_player">
                      {/* <img src={list.image} alt="playerimg" /> */}
                      <img src="/assets/images/logo-icon.png" alt="playerimg" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Squad;
