import { getPlayerListApi, getPlayerListByMatchIdApi } from "../Components/constant/Api";

import { myApi } from "./api";

export const playerApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlayers: builder.query({
      query: () => getPlayerListApi,
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getPlayersByMatchId: builder.mutation({
      query: (data) => ({
        url:getPlayerListByMatchIdApi,
        method:"POST",
        body:data
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const {useGetPlayersQuery,useGetPlayersByMatchIdMutation } = playerApi;
