import React from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../Widgets/Header";
import Navbar from "../../Widgets/Navbar";
import { Link } from "react-router-dom";
import { useGetLiveMatchQuery } from "../../../redux/socketApi";

function LiveMatchList() {
  const { currentData:record,isLoading} = useGetLiveMatchQuery();
  const columns = [
    {
      key: "Sr.No.",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "series",
      text: "Series",
      className: "title",
      align: "left",
      sortable: true,
    },

    {
      key: "match_date",
      text: "Match Date",
      className: "time",
      align: "left",
      sortable: true,
    },

    {
      key: "match_time",
      text: "Match Time",
      className: "time",
      align: "left",
      sortable: true,
    },
    {
      key: "team_a",
      text: "Team A",
      className: "team",
      align: "left",
      sortable: true,
    },
    {
      key: "team_b",
      text: "Team B",
      className: "team",
      align: "left",
      sortable: true,
    },
    {
      key: "matchs",
      text: "Match ",
      className: "match",
      align: "left",
      sortable: true,
    },
    {
      key: "match_type",
      text: "Match Type",
      className: "match",
      align: "left",
      sortable: true,
    },
    {
      key: "match_status",
      text: "Match Status",
      className: "match",
      align: "left",
      sortable: true,
    },

    {
      key: "views",
      text: "View Match",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link to={`/match-detail/${record.match_id}`}>View</Link>
          </>
        );
      },
    },
    {
      key: "list",
      text: "Player List",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link to={`/player-list-by-match/${record.match_id}`}>List</Link>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
              <div className="section-heading">
                <h2>
                  <b>Live Match List</b>
                </h2>
              </div>
             
              <div className="row">
                <div className="col-xxl-5">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center"></div>
                  </div>
                  {isLoading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <ReactDatatable
                      config={config}
                      records={record.liveMatch}
                      columns={columns}
                    />
                  )}
                
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default LiveMatchList;
