import React, { useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../Widgets/Header";
import Navbar from "../../Widgets/Navbar";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  useGetNewsQuery,
  useUpdateNewsStatusMutation,
} from "../../../redux/newsApi";
import { useGetTotalNewsQuery } from "../../../redux/dashboardApi";

function DisApproveNews() {
  const [disable, setDisable] = useState(false);
  const {
    data: record,
    isLoading,
    refetch,
  } = useGetNewsQuery({
    status: 2,
  });
  const [updateNewsStatus] = useUpdateNewsStatusMutation();
  const { refetch: dashboard } = useGetTotalNewsQuery();
  const updateStatus = async (id, status) => {
    setDisable(true);
    const data = {
      id: id,
      status: status,
    };
    updateNewsStatus(data).then((res) => {
      if (res.data.status) {
        toast.dismiss();
        toast.success(res.data.message);
        refetch();
        dashboard();
        setDisable(false);
      } else {
        toast.error(res.data.message);
      }
    });
  };

  const columns = [
    {
      key: "Sr.No.",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "title",
      text: "Title",
      className: "title",
      align: "left",
      sortable: true,
    },

    {
      key: "image",
      text: "Image",
      className: "img",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <img style={{ height: "50px" }} src={record.image} alt="" />
          </>
        );
      },
    },

    {
      key: "pub_date",
      text: "Publish Date",
      className: "Tagline",
      align: "left",
      sortable: true,
    },
    {
      key: "vies",
      text: "View",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link to={`/news-detail/${record.id}`}>View</Link>
          </>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              className="icon_btn"
              onClick={() => updateStatus(record.id, "1")}
              title="Approved"
              disabled={disable}
            >
              <span className="mdi mdi-check-bold"></span>
            </button>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
              <div className="section-heading">
                <h2>
                  <b>Disapproved News List</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-5">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center"></div>
                  </div>
                  {isLoading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <ReactDatatable
                      config={config}
                      records={record}
                      columns={columns}
                    />
                  )}
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default DisApproveNews;
