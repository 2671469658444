import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetNewsQuery, useUpdateNewsStatusMutation } from "../../../redux/newsApi";
import { getNewsListById } from "../../services/News";
import Header from "../../Widgets/Header";
import Navbar from "../../Widgets/Navbar";
function NewsDetail() {
  const { id } = useParams();
  const [record, setRecord] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [content, setContent] = useState([]);
  const [updateNewsStatus,{isLoading}] = useUpdateNewsStatusMutation();
  const { refetch: penrefetch } = useGetNewsQuery({
    status: 0,
  });
  useEffect(() => {
    const getData = async () => {
      let data = {
        id: id,
      };
      let res = await getNewsListById(data);
      if (res.status) {
        setRecord(res.data[0]);
        setContent(JSON.parse(res.data[0].content));
      
      } else {
        setRecord({});
      }
    };
    if (id) {
      getData();
    }
  }, [id, refresh]);

  const updateStatus = async (status) => {
    const data = {
      id: id,
      status: status,
    };
    updateNewsStatus(data).then((res) => {
      if (res.data.status) {
        toast.dismiss();
        toast.success(res.data.message);
        setRefresh(!refresh);
        penrefetch()
      } else {
        toast.error(res.data.message);
      }
    });
   
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
              <div className="row">
                <div className="col-xxl-5">
                <div className="card p-3 p-md-4">
                  {/* <div className="product-list-outer">
                    <div className="add-product-btn text-center"></div>
                  </div> */}
                  <div className="row  align-items-center">
                    <div className="col-md-5">
                      <div>
                        <img className="news_detail_img" src={record.image} alt="" />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div>
                        <h3>{record.title}</h3>
                        <p>{record.description}</p>
                      </div>
                    </div>
                    <div className="col-12 mt-3 mb-4">
                      {content.map((list,index) => {
                        return (
                          <div key={index}
                            dangerouslySetInnerHTML={{ __html: list }}
                          />
                        );
                      })}
                    </div>
                    {record.status === "0"  ? (
                      <div>
                        <button
                          onClick={() => updateStatus("1")}
                          title="Approved"
                          className="btn btn-primary me-3"
                          disabled={isLoading}
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => updateStatus("2")}
                          title="Disapproved"
                          className="btn btn-danger"
                          disabled={isLoading}
                        >
                          Reject
                        </button>
                      </div>
                    ) : (
                      <button disabled>
                        {record.status === "1" ? "Approved" : "Disapproved"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsDetail;
