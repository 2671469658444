import { getSeriesApi, updateSeriesStatusApi } from "../Components/constant/Api";
import { myApi } from "./api";

export const seriesApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getSeries: builder.query({ query: () => getSeriesApi }),
    updateSeriesStatus:builder.mutation({
      query:(data)=>({
          url:updateSeriesStatusApi,
          method:"POST",
          body:data
      })
    })
   
  }),

});

export const { useGetSeriesQuery,useUpdateSeriesStatusMutation } = seriesApi;
