import { getTeamApi } from "../Components/constant/Api";
import { myApi } from "./api";

export const teamApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query({ query: () => getTeamApi }),
   
  }),
});

export const { useGetTeamsQuery } = teamApi;
