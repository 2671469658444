import React from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../Widgets/Header";
import Navbar from "../../Widgets/Navbar";
import { Link } from "react-router-dom";
import { useGetTeamsQuery } from "../../../redux/teamApi";

function Team() {
  const { isLoading, data } = useGetTeamsQuery("");

  const columns = [
    {
      key: "Sr.No.",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "team_name",
      text: "Team Name",
      className: "title",
      align: "left",
      sortable: true,
    },
    {
        key: "team_short",
        text: "Short Name",
        className: "date",
        align: "left",
        sortable: true,
      },
  
    {
      key: "team_image",
      text: "Image",
      className: "img",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {/* <img style={{ height: "50px" }} src={record.team_image} alt="" /> */}
            <img src="/assets/images/logo-icon.png" alt="playerimg" style={{ height: "50px" }}/>
          </>
        );
      },
    },

   
    {
      key: "views",
      text: "Match List",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link to={`/match-list-by-team/${record.team_id}/Upcoming`}>List</Link>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
              <div className="section-heading">
                <h2>
                  <b>Teams List</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-5">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center"></div>
                  </div>
                  {isLoading ? (
                    <div class="text-center">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <ReactDatatable
                      config={config}
                      records={data.data}
                      columns={columns}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default Team;
