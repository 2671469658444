const sideMenuList = [
  {
    id: 1,
    title: "Dashboard",
    route: "/dashboard",
    icon: "mdi mdi-home",
  },


  {
    id: 2,
    title: "Fixture",
    route: "/cricket",
    icon: "mdi mdi-cricket",
    subMenu: [
      {
        id: 1,
        title: "ODI",
        route: "/match/ODI/All",
        icon: "mdi mdi-cricket",
      },
      {
        id: 2,
        title: "T20",
        route: "/match/T20/All",
        icon: "mdi mdi-cricket",
      },
      {
        id: 3,
        title: "Test",
        route: "/match/Test/All",
        icon: "mdi mdi-cricket",
      },
      
      {
        id: 5,
        title: "Series ",
        route: "/series",
        icon: "mdi mdi-tournament",
      },
    
    ],
  },
  {
    id: 4,
    title: "Live Matches",
    route: "/live-matches",
    icon: "mdi mdi-cricket",
  },
  {
    id: 3,
    title: "Players",
    route: "/players",
    icon: "mdi mdi-account-group",
  },
  {
    id: 4,
    title: "Teams",
    route: "/teams",
    icon: "mdi mdi-account-group",
  },
  {
    id: 11,
    title: "News List",
    route: "/news-management",
    icon: "mdi mdi-newspaper",
    subMenu: [
      {
        id: 1,
        title: "Pending",
        route: "/news-pending",
        icon: "mdi mdi-newspaper",
      },
      {
        id: 2,
        title: "Approved",
        route: "/news-approved",
        icon: "mdi mdi-check-decagram-outline",
      },
      {
        id: 3,
        title: "Disapproved",
        route: "/news-disapproved",
        icon: "mdi mdi-movie-remove-outline",
      },
    
    ],
  },
 
  // {
  //   id: 12,
  //   title: "How To Play",
  //   route: "/Settings",
  //   icon: "mdi mdi-cricket",
  // },
  {
    id: 13,
    title: "Terms And Condition",
    route: "/TAC",
    icon: "mdi mdi-format-list-checks",
  },
  {
    id: 14,
    title: "Privacy Policy ",
    route: "/privacyPolicy",
    icon: "mdi mdi-format-list-checks",
  },
  // {
  //   id: 15,
  //   title: "Contact Us ",
  //   route: "/Settings",
  //   icon: "mdi mdi-contacts-outline",
  // },

 

  
];
export default sideMenuList;
