import { getMatchByTeamIdApi, getMatchListApi } from "../Components/constant/Api";
import { myApi } from "./api";

export const matchApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getMatches: builder.query({
      query: (data) => ({
        url: getMatchListApi+"/"+data.type+"/"+data.status,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getMatchByTeam: builder.mutation({
      query: (data) => ({
        url:getMatchByTeamIdApi,
        method:"POST",
        body:data
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),

  }),
});

export const {useGetMatchesQuery,useGetMatchByTeamMutation } = matchApi;
