import { getTotalFixtureApi, getTotalNewsApi, getTotalPlayersApi, getTotalSeriesApi, getTotalTeamsApi } from "../Components/constant/Api";
import { myApi } from "./api";

export const dashboardApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define a query endpoint for total news data
    getTotalNews: builder.query({
      query: () => getTotalNewsApi, // Use an arrow function here
      transformResponse: (response) => {
        if (response.status) {
          return response.data[0] || [];
        }
        return []; // Return an empty array for other response statuses
      },
    }),
    getTotalSeries: builder.query({
        query: () => getTotalSeriesApi, // Use an arrow function here
        transformResponse: (response) => {
          if (response.status) {
            return response.data[0] || [];
          }
          return []; // Return an empty array for other response statuses
        },
      }),
      getTotalPlayers: builder.query({
        query: () =>getTotalPlayersApi, // Use an arrow function here
        transformResponse: (response) => {
          if (response.status) {
            return response.data[0] || [];
          }
          return []; // Return an empty array for other response statuses
        },
      }),
      getTotalFixture: builder.query({
        query: () =>getTotalFixtureApi, // Use an arrow function here
        transformResponse: (response) => {
          if (response.status) {
            return response.data[0] || [];
          }
          return []; // Return an empty array for other response statuses
        },
      }),
      getTotalTeam: builder.query({
        query: () =>getTotalTeamsApi, // Use an arrow function here
        transformResponse: (response) => {
          if (response.status) {
            return response.data[0] || [];
          }
          return []; // Return an empty array for other response statuses
        },
      }),
  }),
});

// Destructure and export the query hook
export const { useGetTotalNewsQuery,useGetTotalSeriesQuery,useGetTotalPlayersQuery,useGetTotalFixtureQuery,useGetTotalTeamQuery } = dashboardApi;
