import React, { useState, useEffect,Fragment } from "react";

function Scores(props) {
  const [score, setScore] = useState([]);
  const [teamAShort, setTeamAShort] = useState("");
  const [teamBShort, setTeamBShort] = useState("");
  const [teamAId, setTeamAId] = useState("");
  const [teamBId, setTeamBId] = useState("");
  const [activeTag, setActiveTag] = useState("");
  const [filterScore, setFilterScore] = useState([]);
  useEffect(() => {
    if (props) {
      setScore(props.score);
      setTeamAShort(props.teamAShort);
      setTeamBShort(props.teamBShort);
      setTeamAId(props.teamAId);
      setTeamBId(props.teamBId);
      console.log("props.teamAId",props.teamBId)
      if(!activeTag){

        setActiveTag(props.teamAId);
       
      }
      // props.teamAShort
      let data = props.score.filter((item) => {
        const parsedTeam = JSON.parse(item.team);
        return parsedTeam.team_id === activeTag;
      });
      setFilterScore(data);
    }
  }, [props,activeTag]);

  const filterRecord = (team_id) => {
    let data = score.filter((item) => {
      const parsedTeam = JSON.parse(item.team);
      return parsedTeam.team_id === team_id;
    });
    setFilterScore(data);
  };
 
  return (
    <div>
      <div className="card p-3 p-md-4">
      <div className="">
        <ul className="nav nav-pills nav-fill2 ">
          <li
            className="nav-item"
            onClick={() => {
              setActiveTag(teamAId);
              filterRecord(teamAId);
            }}
          >
            <span
              className={`nav-link ${activeTag === teamAId ? "active" : ""}`}
              aria-current="page"
            >
              {teamAShort}
            </span>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActiveTag(teamBId);
              filterRecord(teamBId);
            }}
          >
            <span
              className={`nav-link ${activeTag === teamBId ? "active" : ""}`}
              aria-current="page"
            >
              {teamBShort}
            </span>
          </li>
        </ul>
      </div>
      <div>
        {filterScore.map((list, index) => {
          let team=JSON.parse(list.team)
          let batter=JSON.parse(list?.batsman)
          let bowler=JSON.parse(list?.bowler)
          let fallWicket=JSON.parse(list?.fallwicket)
         return ( <Fragment key={index}>
          <div className="team-class">
            <div>{team.name} Innings</div>
            <div>{team.score}-{team.wicket} ({team.over} Ov)</div>
          </div>
              
         {/*=============== Batter =========================*/}
          <div className="p012">
          <div className="row bg2 div_table">
             <div className="col-md-3">Batter</div>
             <div className="col-md-3"></div>
             <div className="col-md-1">R</div>
             <div className="col-md-1">B</div>
             <div className="col-md-1">4s</div>
             <div className="col-md-1">6s</div>
             <div className="col-md-2">SR</div>
             </div>
             <div className="row div_table">
              {batter?.map((batterList,index)=>{
                  return (<Fragment key={index}>
                  <div className="col-md-3">{batterList.name}</div>
                  <div className="col-md-3">{batterList.out_by}</div>
             <div className="col-md-1">{batterList.run}</div>
             <div className="col-md-1">{batterList.ball}</div>
             <div className="col-md-1">{batterList.fours}</div>
             <div className="col-md-1">{batterList.sixes}</div>
             <div className="col-md-2">{batterList.strike_rate}</div>
                  </Fragment>)
              })}
              <div className="col-md-6">Extras</div>
              <div className="col-md-6">{team.extras}</div>
              <div className="col-md-6">Total</div>
              <div className="col-md-6">{team.score} ({team.wicket} wkts, {team.over} Ov)</div>
          </div>


{/*=============== Fall of Wickets =========================*/}
          <div className="row bg2 div_table mt-4">
            
            <div className="col-12 ">Fall of Wickets</div>
            </div>
            <div className="row div_table">
           <div className="col-12">

             {fallWicket && fallWicket.map((fallList,index)=>{
                 return (<>
                 <span key={index}>{fallList.score}-{fallList.wicket} ({fallList.player},{fallList.over})</span>,
                 </>)
             })}
           </div>
             
         </div>



{/*=============== Bowler =========================*/}
<div className="row bg2 div_table mt-4">
            
             <div className="col-md-4">Bowler</div>
       
             <div className="col-md-1">O</div>
             <div className="col-md-1">M</div>
             <div className="col-md-1">R</div>
             <div className="col-md-1">W</div>
             
             <div className="col-md-2">ECO</div>
            </div>
             <div className="row  div_table">
              {bowler && bowler.map((bowlerList,index)=>{
                  return (<Fragment key={index}>
                  <div className="col-md-4">{bowlerList.name}</div>
                  <div className="col-md-1">{bowlerList.over}</div>
             <div className="col-md-1">{bowlerList.maiden}</div>
             <div className="col-md-1">{bowlerList.run}</div>
             <div className="col-md-1">{bowlerList.wicket}</div>
        
             <div className="col-md-2">{bowlerList.economy}</div>
                  </Fragment>)
              })}
              
          </div>
          
          </div>
          </Fragment>
         )
        })}
      </div>
    </div></div>
  );
}

export default Scores;
