import React, { useState, useEffect, Fragment } from "react";

function Commentry(props) {
  const [matchDetail, setMatchDetail] = useState({});
  const [matchInfo, setMatchInfo] = useState({});
  const [liveScore, setLiveScore] = useState([]);
  const [batsMan, setBatsman] = useState([]);
  const [bowler, setBowler] = useState({});
  const [partnerShip, setPartnerShip] = useState({})
  const [lastWicket, setLastWicket] = useState({})
  const [toss, setToss] = useState("")
  const [firstCircle, setfirstCircle] = useState("")
  const [need, setNeed] = useState("")
  const [secondTeam, setSecondTeam] = useState("")
  const [teamAId, setTeamAId] = useState("")
  const [teamBId, setTeamBId] = useState("")
  const [battingTeam, setBattingTeam] = useState("")
  const [cRR, setCRR] = useState("")
  useEffect(() => {
    if (props) {
      setMatchDetail(props.matchDetail);
      setToss(props.toss)
      setSecondTeam(props.secondTeam)

      if (props.liveScore.length > 0) {
        let bats = props.liveScore[0].batsman;
        let ball = props.liveScore[0].bowler;
        let partner=props.liveScore[0].partnership
        let lastW=props.liveScore[0].lastwicket
        setBatsman(JSON.parse(bats));
        setBowler(JSON.parse(ball));
        setPartnerShip(JSON.parse(partner))
        setLastWicket(JSON.parse(lastW))
        setfirstCircle(props.liveScore[0].first_circle)
        setNeed(props.liveScore[0].run_need)
        setTeamAId(props.liveScore[0].team_a_id)
        setTeamBId(props.liveScore[0].team_b_id)
        setBattingTeam(props.liveScore[0].batting_team)
        setCRR(props.liveScore[0].curr_rate)
      }
      setLiveScore(props.liveScore);
      
      if (props?.matchDetail?.match_info) {
        setMatchInfo(JSON.parse(props?.matchDetail?.match_info));
      }
    }
  }, [props]);
  return (
    <div className="card p-3 p-md-4">
      {matchDetail?.result?"":matchDetail?.toss?<p className="text-danger">{matchDetail?.toss}</p>:<p className="text-danger">{matchDetail?.match_delay}</p>}
      {matchDetail?.team_a_scores ? (
        <h2 className={`live_bold ${matchDetail?.team_a_short===secondTeam && !matchDetail?.result?"active":""}`}>
          {matchDetail?.team_a_short} {matchDetail?.team_a_scores} (
          {matchDetail?.team_a_over})
          {battingTeam && battingTeam===teamAId?  <span>CRR: {cRR}</span>:""}
        </h2>
      ) : (
        ""
      )}
      {matchDetail?.team_b_scores ? (
        <h2 className={`live_bold ${matchDetail?.team_b_short===secondTeam && matchDetail?.result===""?"active":""}`}>
          {matchDetail?.team_b_short} {matchDetail?.team_b_scores} (
          {matchDetail?.team_b_over})
          {battingTeam && battingTeam===teamBId?  <span>CRR: {cRR}</span>:""}
        </h2>
      ) : (
        ""
      )}
      {matchDetail?.result?"":<p className="text-danger">{firstCircle}</p>}
      {/* {firstCircle==="Inning Break"? <p className="text-danger">{firstCircle}</p>:""} */}
      {need && !matchDetail?.result? <p className="text-danger">{secondTeam} need {need} runs</p>:""}
      <p className="text-danger">{matchDetail?.result}</p>

      {liveScore.length > 0 && matchDetail.match_status!=='Finished'? (
        <div className="row">
          <div className="col-md-9">
            <div>
           
              <div className="ps-2 pe-2">
              <div className="row bg2 div_table">
                <div className="col-3">Batter</div>
                <div className="col-1">R</div>
                <div className="col-1">B</div>
                <div className="col-1">4s</div>
                <div className="col-1">6s</div>
                <div className="col-2">SR</div>
                <div className="col-md-3 col-3"></div>
                </div>
              
               
                {batsMan?.map((batterList, index) => {
                  return (
                    <Fragment key={index}>
                       <div className="row div_table">
                      <div className="col-3">{batterList.name}</div>
                      <div className="col-1">{batterList.run}</div>
                      <div className="col-1">{batterList.ball}</div>
                      <div className="col-1">{batterList.fours}</div>
                      <div className="col-1">{batterList.sixes}</div>
                      <div className="col-2">{batterList.strike_rate}</div>
                      <div className="col-3"></div>
                      </div>
                    </Fragment>
                  );
                })}
             
             
              <div className="row div_table bg2">
                <div className="col-md-3">Bowler</div>

                <div className="col-md-1">O</div>

                <div className="col-md-1">R</div>
                <div className="col-md-1">W</div>

                <div className="col-md-2">ECO</div>
                <div className="col-md-1"></div>
                <div className="col-md-3"></div>
                </div>
               
                {bowler && (
                  <>
                   <div className="row div_table">
                    <div className="col-md-3">{bowler.name}</div>
                    <div className="col-md-1">{bowler.over}</div>

                    <div className="col-md-1">{bowler.run}</div>
                    <div className="col-md-1">{bowler.wicket}</div>

                    <div className="col-md-2">{bowler.economy}</div>
                    <div className="col-md-1"></div>
                    <div className="col-md-3"></div>
                    </div>
                  </>
                )}
             
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <p className="bg2 p-1 ps-2">Key Stats</p>
              {partnerShip?.ball>0?<p><strong>Partnership: </strong>{partnerShip.run}({partnerShip.ball})</p>:""}
              <p><strong>Last Wkt: </strong>{lastWicket?.player} {lastWicket?.run}({lastWicket?.ball})</p>
              <p><strong>Toss: </strong>{toss}</p>
          </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div>
        <div className="row mt-4">
          {/* <div className="col-12">
          <div dangerouslySetInnerHTML={{ __html: matchDetail?.session }} />
          </div> */}
          <div className="col-md-3">
            <h3 className="matc_info">Match Info</h3>
            <div className="left_matc_info">
            <p>
              <span>
                <strong>Match</strong>
              </span>
              <span>
                {matchDetail?.team_a_short} vs {matchDetail?.team_b_short}, {matchDetail?.series}
              </span>
            </p>
            <p>
              <span>
                <strong>Date</strong>
              </span>
              <span>{matchDetail?.match_date}</span>
            </p>
            <p>
              <span>
                <strong>Toss</strong>
              </span>
              <span>{matchDetail?.toss}</span>
            </p>
            <p>
              <span>
                <strong>Time</strong>
              </span>
              <span>{matchDetail?.match_time}</span>
            </p>
            <p>
              <span>
                <strong>Venue</strong>
              </span>
              <span>{matchDetail?.venue}</span>
            </p>
            </div>
          </div>
          <div className="col-md-9">
            <div dangerouslySetInnerHTML={{ __html: matchInfo?.match_info }} />
            <div dangerouslySetInnerHTML={{ __html: matchInfo?.pitch_venue }} />
            <div dangerouslySetInnerHTML={{ __html: matchInfo?.stats }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Commentry;
