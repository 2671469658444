import { myApi } from "./api";
import { io } from "socket.io-client";
import { SocketUrl } from "../Components/constant/BaseUrl";

const socket = io(SocketUrl,{
  transports: ["websocket"]
});
export const socketApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getMatchData: builder.query({
      queryFn: () => {
        return {
          data: {
            matchData: [],
            matchDataLive: [],
          },
        };
      },
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
      ) {
        try {
          await cacheDataLoaded;

          if (!!arg?.matchId) {
            socket.emit("get-match-score", arg?.matchId);
            socket.on(`recieve-score-${arg.matchId}`, (res) => {
              if (res?.data?.length > 0) {
                updateCachedData((draft) => {
                  return {
                    ...draft,
                    matchData: res.data,
                    matchDataLive: res.liveScore,
                  };
                });
              }
            });
          }

          await cacheEntryRemoved;
        } catch {
          // if cacheEntryRemoved resolved before cacheDataLoaded,
          // cacheDataLoaded throws
        }
      },
    }),
    getMatchById: builder.query({
      queryFn: () => {
        return {
          data: {
            matchData: [],
          },
        };
      },
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
      ) {
        try {
          await cacheDataLoaded;

          if (!!arg?.matchId) {
            socket.emit("get-match-by-id", arg.matchId);
            socket.on(`recieve-match-${arg.matchId}`, (res) => {
              if (res?.status) {
                updateCachedData((draft) => {
                  return {
                    ...draft,
                    matchData: res.data,
                  };
                });
              }
            });
          }

          await cacheEntryRemoved;
        } catch {
          // if cacheEntryRemoved resolved before cacheDataLoaded,
          // cacheDataLoaded throws
        }
      },
    }),
    getMatchCommentry: builder.query({
      queryFn: () => {
        return {
          data: {
            matchCommentry: [],
          },
        };
      },
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
      ) {
        try {
          await cacheDataLoaded;

          if (!!arg?.matchId) {
            socket.emit("get-match-commentry", arg.matchId);
            socket.on(`recieve-commentry-${arg.matchId}`, (res) => {
              if (res?.status) {
                updateCachedData((draft) => {
                  return {
                    ...draft,
                    matchCommentry: res.data,
                  };
                });
              }
            });
          }

          await cacheEntryRemoved;
        } catch {
          // if cacheEntryRemoved resolved before cacheDataLoaded,
          // cacheDataLoaded throws
        }
      },
    }),
    getMatchSquad: builder.query({
      queryFn: () => {
        return {
          data: {
            matchSquad: [],
          },
        };
      },
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
      ) {
        try {
          await cacheDataLoaded;

          if (!!arg?.matchId) {
            socket.emit("get-match-squad", arg?.matchId);
            socket.on(`recieve-squad-${arg.matchId}`, (res) => {
              if (res?.status) {
                updateCachedData((draft) => {
                  return {
                    ...draft,
                    matchSquad: res.data,
                  };
                });
              }
            });
          }

          await cacheEntryRemoved;
        } catch {
          // if cacheEntryRemoved resolved before cacheDataLoaded,
          // cacheDataLoaded throws
        }
      },
    }),
    getLiveMatch: builder.query({
      queryFn: () => {
        return {
          data: {
            liveMatch: [],
          },
        };
      },
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
      ) {
        try {
          await cacheDataLoaded;
          socket.emit("get-live-match");

          socket.on(`recieve-live-match`, (res) => {
            if (res?.status) {
              updateCachedData((draft) => {
                return {
                  ...draft,
                  liveMatch: res.data,
                };
              });
            }
          });

          await cacheEntryRemoved;
        } catch {
          // if cacheEntryRemoved resolved before cacheDataLoaded,
          // cacheDataLoaded throws
        }
      },
    }),
  }),
});

export const {
  useGetMatchDataQuery,
  useGetMatchByIdQuery,
  useGetMatchCommentryQuery,
  useGetMatchSquadQuery,
  useGetLiveMatchQuery,
} = socketApi;
