import { getNewsListApi, updateNewsStatusApi } from "../Components/constant/Api";
import { myApi } from "./api";

export const newsApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query({
      query: (post) => ({
        url: getNewsListApi+"/" + post.status +"/newsPage",
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    updateNewsStatus:builder.mutation({
      query:(data)=>({
          url:updateNewsStatusApi,
          method:"POST",
          body:data
      })
    })
  }),
});

export const {useGetNewsQuery,useUpdateNewsStatusMutation } = newsApi;
