import React, { useEffect } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../Widgets/Header";
import Navbar from "../../Widgets/Navbar";
import { useParams } from "react-router-dom";
import { useGetPlayersByMatchIdMutation } from "../../../redux/playerApi";

function PlayersListByMatchId() {
  const { id } = useParams();
  const [getPlayersByMatchId, { data: record, isLoading }] =
    useGetPlayersByMatchIdMutation();

  useEffect(() => {
    const getData = async () => {
      let data = {
        match_id: id,
      };
      await getPlayersByMatchId(data);
    };
    if (id) {
      getData();
    }
  }, [id, getPlayersByMatchId]);

  const columns = [
    {
      key: "Sr.No.",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "team_name",
      text: "Team Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "short_name",
      text: "Team Short Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "image",
      text: "Image",
      className: "img",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {/* <img style={{ height: "50px" }} src={record.image} alt="" /> */}
            <img src="/assets/images/logo-icon.png" alt="playerimg" style={{ height: "50px" }}/>
          </>
        );
      },
    },
    {
      key: "player_name",
      text: "Player Name",
      className: "name",
      align: "left",
      sortable: true,
    },

    {
      key: "player_role",
      text: "Player Role",
      className: "date",
      align: "left",
      sortable: true,
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
              <div className="section-heading">
                <h2>
                  <b>Players List</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-5">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center"></div>
                  </div>
                  {isLoading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <ReactDatatable
                      config={config}
                      records={record}
                      columns={columns}
                    />
                  )}
                
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default PlayersListByMatchId;
