import React from "react";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import {
  useGetTotalFixtureQuery,
  useGetTotalNewsQuery,
  useGetTotalPlayersQuery,
  useGetTotalSeriesQuery,
  useGetTotalTeamQuery
} from "../../redux/dashboardApi";

function Dashboard() {
  const { data: News, isLoading } = useGetTotalNewsQuery();
  const { data: Series, isLoading: isSeriesLoading } = useGetTotalSeriesQuery();
  const { data: Teams, isLoading: isTeamsLoading } = useGetTotalTeamQuery();
  const { data: Players, isLoading: isPlayersLoading } =
    useGetTotalPlayersQuery();
  const { data: Fixture, isLoading: isFixtureLoading } =
    useGetTotalFixtureQuery();
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid p-0 mt-2">
            <div className="row dashbord_man">
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/match/ODI/All">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total ODI</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isFixtureLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                Fixture?.ODI_Count
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span className="mdi mdi-cricket"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/match/T20/All">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total T20</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isFixtureLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                Fixture?.T20_Count
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <i className="mdi mdi-cricket"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/match/Test/All">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Test</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isFixtureLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                Fixture?.TEST_Count
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <i className="mdi mdi-cricket"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/series">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Series</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isSeriesLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                Series?.total_series
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <i className="mdi mdi-tournament"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/players">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Players</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isPlayersLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                Players?.total_players
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <i className="mdi mdi-account-group"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/teams">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Teams</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isTeamsLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                Teams?.total_teams
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <i className="mdi mdi-account-group"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/news-pending">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Pending News</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                News?.pending
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <i className="mdi mdi-newspaper"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/news-approved">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Approved News</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                News?.approved
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <i className="mdi mdi-check-decagram-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/news-disapproved">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Disapproved News</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                News?.rejected
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <i className="mdi mdi-movie-remove-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default Dashboard;
