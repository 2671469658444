import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../Widgets/Header";
import Navbar from "../../Widgets/Navbar";
import Commentry from "./MatchComponents/Commentry";
import FullCommentry from "./MatchComponents/FullCommentry";
import Scores from "./MatchComponents/Scores";
import Squad from "./MatchComponents/Squad";
import {
  useGetMatchByIdQuery,
  useGetMatchCommentryQuery,
  useGetMatchDataQuery,
  useGetMatchSquadQuery,
} from "../../../redux/socketApi";
function MatchDetail() {
  const { id } = useParams();
  const { currentData: scoreData } = useGetMatchDataQuery({ matchId: id });
  const { currentData: matchData } = useGetMatchByIdQuery({ matchId: id });

  const { currentData: matchCommentry } = useGetMatchCommentryQuery({
    matchId: id,
  });
  const { currentData: matchSquad } = useGetMatchSquadQuery({
    matchId: id,
  });

  const [activeTag, setActiveTag] = useState("Commentary");
  const [matchDetail, setMatchDetail] = useState({});
  const [squadDetail, setSquadDetail] = useState({});
  const [commentry, setCommentry] = useState({});
  const [score, setScore] = useState([]);
  const [liveScore, setLiveScore] = useState([]);
  const [teamAShort, setTeamAShort] = useState("");
  const [teamBShort, setTeamBShort] = useState("");
  const [teamAId, setTeamAId] = useState("");
  const [teamBId, setTeamBId] = useState("");
  const [secondTeam, setSecondTeam] = useState("");
  const [toss, setToss] = useState("");
  useEffect(() => {
    console.log(scoreData,"scoreData")
    if (scoreData?.matchData.length > 0) {
     
      console.log(scoreData.matchData.length)
      setScore(scoreData.matchData);
      setToss(scoreData.matchData[0].toss);
      setLiveScore(scoreData.matchDataLive);
      if (scoreData.matchData.length > 1) {
        let secondteam = JSON.parse(scoreData.matchData[1].team);
        setSecondTeam(secondteam.short_name);
      }
    } else {
      setScore([]);
    }
  }, [scoreData]);

  useEffect(() => {
    if (matchData?.matchData.length > 0) {
      setMatchDetail(matchData.matchData[0]);
      setTeamAShort(matchData.matchData[0].team_a_short);
      setTeamBShort(matchData.matchData[0].team_b_short);
      setTeamAId(matchData.matchData[0].team_a_id);
      setTeamBId(matchData.matchData[0].team_b_id);
    } else {
      setMatchDetail({});
    }
  }, [matchData]);

  useEffect(() => {
    if (matchCommentry?.matchCommentry.length > 0) {
      setCommentry(matchCommentry?.matchCommentry[0]);
    } else {
      setSquadDetail({});
    }
  }, [matchCommentry]);

  useEffect(() => {
    if (matchSquad?.matchSquad.length > 0) {
      setSquadDetail(matchSquad?.matchSquad[0]);
    } else {
      setSquadDetail({});
    }
  }, [matchSquad]);

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
              <div className="section-heading">
                <h3>
                  <b>
                    {matchDetail?.team_a} VS {matchDetail?.team_b},{" "}
                    {matchDetail?.matchs} ({matchDetail?.match_type})
                  </b>
                </h3>
                <div className="mc-detail">
                  <p>
                    <b>Series: </b>
                    {matchDetail?.series}
                  </p>
                  <p>
                    <b>Venue: </b>
                    {matchDetail?.venue}
                  </p>
                  <p>
                    <b>Date & Time: </b>
                    {matchDetail?.date_time}
                  </p>
                </div>
              </div>
              <div>
                <ul className="nav nav-tabs2">
                  <li className="nav-item">
                    <span
                      className={`nav-link ${
                        activeTag === "Commentary" ? "active" : ""
                      }`}
                      aria-current="page"
                      onClick={() => {
                        setActiveTag("Commentary");
                      }}
                    >
                      Commentary
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`nav-link ${
                        activeTag === "Scorecard" ? "active" : ""
                      }`}
                      onClick={() => {
                        setActiveTag("Scorecard");
                      }}
                    >
                      Scorecard
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`nav-link ${
                        activeTag === "Squads" ? "active" : ""
                      }`}
                      onClick={() => {
                        setActiveTag("Squads");
                      }}
                    >
                      Squads
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`nav-link ${
                        activeTag === "Full_Commentary" ? "active" : ""
                      }`}
                      tabIndex="-1"
                      onClick={() => {
                        setActiveTag("Full_Commentary");
                      }}
                    >
                      Full Commentary
                    </span>
                  </li>
                </ul>
              </div>
              <div className="row">
                <div className="col-xxl-5">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center"></div>
                  </div>
                  {activeTag === "Commentary" ? (
                    <Commentry
                      matchDetail={matchDetail}
                      liveScore={liveScore}
                      toss={toss}
                      secondTeam={secondTeam}
                    />
                  ) : (
                    ""
                  )}
                  {activeTag === "Squads" ? (
                    <Squad squadDetail={squadDetail} />
                  ) : (
                    ""
                  )}
                  {activeTag === "Scorecard" ? (
                    <Scores
                      score={score}
                      teamAShort={teamAShort}
                      teamBShort={teamBShort}
                      teamAId={teamAId}
                      teamBId={teamBId}
                    />
                  ) : (
                    ""
                  )}
                  {activeTag === "Full_Commentary" ? (
                    <FullCommentry commentry={commentry} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default MatchDetail;
