import React, { useEffect, useState } from "react";
import Header from "../../Widgets/Header";
import Navbar from "../../Widgets/Navbar";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import { useGetDetailQuery, useSetDetailMutation } from "../../../redux/detailApi";
function TermsAndConditions() {
  const { data: descriptions } = useGetDetailQuery({
    type: "TAC",
  });
  const [setDetail] = useSetDetailMutation();
  const [description, setDescription] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    if (descriptions) {
      setDescription(descriptions);
    }
  }, [descriptions]);


  const updateTAC = async (id) => {
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 3000);
    if (description === "") {
      setDisable(true);
      setDescriptionErr("This field is required");
      return false;
    }
    
    let data = {
      type: "TAC",
      text: description,
    };
    setDetail(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      }
    });
  };
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
              <div className="section-heading">
                <h2>
                  <b>Terms And Conditions</b>
                </h2>
              </div>
              <div className="row">
                <div className="col-xxl-5">
                  <div className="product-list-outer">
                    <div className="add-product-btn text-center"></div>
                  </div>

                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                   
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "fontSize",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                       
                        "blockQuote",
                      ],
                      
                      heading: {
                        options: [
                          {
                            model: "paragraph",
                            title: "Paragraph",
                            class: "ck-heading_paragraph",
                          },
                          {
                            model: "heading1",
                            view: "h1",
                            title: "Heading 1",
                            class: "ck-heading_heading1",
                          },
                          {
                            model: "heading2",
                            view: "h2",
                            title: "Heading 2",
                            class: "ck-heading_heading2",
                          },
                          {
                            model: "heading3",
                            view: "h3",
                            title: "Heading 3",
                            class: "ck-heading_heading3",
                          },
                        ],
                      },
                      fontSize: {
                        options: ['tiny', 'small', 'default', 'big', 'bigger'],
                      },
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                      if(data){

                        setDescriptionErr("")
                      }
                    }}
                  />
                  <p className="text-danger">{descriptionErr}</p>
                  <button
                    onClick={updateTAC}
                    type="button"
                    className="btn btn-primary ml-5 mt-4 "
                    disabled={disable}
                  >
                   Save
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
